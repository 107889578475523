@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  font-family: "Poppins";
  scroll-behavior: smooth;
}

.scrollContainer::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollContainer::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollContainer::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 500px;
}

/* Handle on hover */
.scrollContainer::-webkit-scrollbar-thumb:hover {
  background: #bdbbbb;
}

.scrollContainerForSidebar::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollContainerForSidebar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollContainerForSidebar::-webkit-scrollbar-thumb {
  background: #4f6cc4;
  border-radius: 500px;
}

/* Handle on hover */
.scrollContainerForSidebar::-webkit-scrollbar-thumb:hover {
  background: #4f6cc4;
}

.skeleton {
  background-color: #e2e5e7;

  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.5),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

.faded-black {
  background-color: #161616;
}

.max-overlay {
  z-index: 999 !important;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

.react-joyride__overlay {
  z-index: 10px !important;
}

[data-test-id="button-primary"] {
  border-radius: 0.75rem !important;
}
